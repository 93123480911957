import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Link,
  Container,
  AppBar,
  Button,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import NavMenu from "./components/NavMenu";
import usePathParameter from "./utils/usePathParameter";
import { landingThane, homeAwardsThane } from "./data/ThaneIndividualSiteData";
import { landingMain, homeAwardsMain } from "./data/MainSiteData";
import {
  landingCbgPalava,
  homeAwardsCbgPalava,
} from "./data/CbgPalavaIndividualSiteData";
import {
  landingLsgPalava,
  homeAwardsLsgPalava,
} from "./data/LsgPalavaIndividualSiteData";
import {
  landingTaloja,
  homeAwardsTaloja,
} from "./data/TalojaIndividualSiteData";
import AdmissionsTicker from "./components/AdmissionsTicker";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    textAlign: "center",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column-reverse",
    },
  },
  mainContainer: {
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  calcDiv: {
    height: "calc(100vh - 220px)",
    [theme.breakpoints.down("sm")]: {
      // height: 'calc(100vh - 476px)',
      height: "100vw",
      margin: "1rem 0",
    },
  },
  font2: {
    fontFamily: "Lato, sans-serif",
    fontWeight: "Bold",
    fontStyle: "initial",
    fontSize: "19px",
    [theme.breakpoints.down("md")]: {
      fontSize: "13px",
    },
  },
  mainCta: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  logo: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: "240px",
    },
  },
  slogan: {
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      fontSize: "48px",
    },
  },
  Link: {
    textDecoration: "none",
  },
  mainTypo: {
    padding: "45px 0",
    [theme.breakpoints.down("md")]: {
      padding: "45px 0",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  typo: {
    fontSize: "50px", //72px prev
    fontFamily: "Bodoni",
    fontWeight: "bold",
    color: "#263657",
    [theme.breakpoints.down("lg")]: {
      fontSize: "50px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "x-large",
      margin: "1rem 3rem",
    },
  },
  awards: {
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
    },
  },
  awardsMain: {
    padding: "2.4rem",
    [theme.breakpoints.down("md")]: {
      padding: "1rem",
    },
  },
  paperContainer: {
    backgroundImage: `url(/assets/bg8.gif)`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundAttachment: "initial",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      backgroundImage: `url(/assets/bg8mobile.gif)`,
      backgroundPosition: "center center",
    },
  },
  container: {
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  mainCtaDiv: {
    margin: "1rem 1rem 2rem 1rem",
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
      margin: "9rem 0 1rem 0",
      textAlign: "center",
    },
  },
  ctaLink: {
    textDecoration: "none",
    [theme.breakpoints.down("md")]: {
      marginRight: "6px",
    },
  },
  ctaLink1: {
    textDecoration: "none",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  btn: {
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
}));

export default function LandingPageMain({ goToNextTarget }) {
  const classes = useStyles();
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane } =
    usePathParameter();
  const [landingContent, setLandingContent] = useState([]);
  const [awards, setAwards] = useState({});

  useEffect(() => {
    if (isThane) {
      setLandingContent(landingThane);
      setAwards(homeAwardsThane);
    } else if (isCbgPalava) {
      setLandingContent(landingCbgPalava);
      setAwards(homeAwardsCbgPalava);
    } else if (isLsgPalava) {
      setLandingContent(landingLsgPalava);
      setAwards(homeAwardsLsgPalava);
    } else if (isTaloja) {
      setLandingContent(landingTaloja);
      setAwards(homeAwardsTaloja);
    } else if (!isIndividual) {
      setLandingContent(landingMain);
      setAwards(homeAwardsMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  return (
    <>
      <Box sx={{ width: "100vw", height: "100%" }}>
        <AppBar position="static" elevation={0} sx={{ bgcolor: "#fff" }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <NavMenu />
              {/* <Search /> */}
            </Box>
            {/* <a
              href="/university-fair"
              rel="noreferrer"
              className={classes.ctaLink1}
            >
              <Button
                color="primary"
                variant="outlined"
                sx={{ mr: 2 }}
                onClick={() => {}}
              >
                LWS University Fair
              </Button>
            </a> */}
            <AdmissionsTicker />
          </Toolbar>
        </AppBar>

        <Box className={classes.paperContainer}>
          <Grid Container className={classes.container}>
            <Grid item xs={12} className={classes.calcDiv}>
              {/* style={{  height: 'calc(100vh - 218px)' }} */}
              {/* padding: '7rem 0 3rem', */}
              <Container className={classes.mainContainer} maxWidth="xl">
                <Grid container className={classes.root}>
                  <Grid item md={7} xs={12} className={classes.mainTypo}>
                    <Typography className={classes.typo} variant="h1">
                      {landingContent?.title1}
                      <br />
                      {landingContent?.title2}
                    </Typography>
                  </Grid>
                  <Grid item md={5} xs={12}>
                    {/* <img src={Logo} alt="" width="350px" class="responsiveImg" className={classes.logo} /> */}
                  </Grid>
                </Grid>
              </Container>
            </Grid>

            <Grid item xs={12} className={classes.awardsMain}>
              <Grid container>
                <Grid
                  item
                  md={7}
                  xs={12}
                  className={classes.awards}
                  sx={{ display: "flex", alignItems: "end" }}
                >
                  <Box
                    className={classes.mainCtaDiv}
                    style={{ marginRight: 0 }}
                  >
                    <Link
                      href={`https://applyonline.lodhaworldschool.com${
                        localStorage.getItem("queryString") ?? ""
                      }`}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.Link}
                    >
                      <Button
                        color="secondary"
                        variant="contained"
                        disableElevation
                        sx={{ mr: 2 }}
                        className="flash-button"
                      >
                        Admissions Open for AY 2025-26
                      </Button>
                    </Link>

                    <a
                      href="/university-fair"
                      rel="noreferrer"
                      className={classes.ctaLink}
                    >
                      <Button
                        color="primary"
                        variant="outlined"
                        sx={{ mr: 2 }}
                        onClick={() => {}}
                      >
                        LWS University Fair
                      </Button>
                    </a>
                  </Box>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={awards.imageSource} alt="" width="40px" />
                    <Box sx={{ textAlign: "left", ml: 2 }}>
                      <Typography
                        variant="subtitle1"
                        color="primary"
                        className={classes.font2}
                        id={awards.id}
                      ></Typography>
                    </Box>
                  </div>
                </Grid>
                <Grid
                  item
                  md={5}
                  xs={12}
                  className={classes.mainCta}
                  sx={{ textAlign: "end", alignSelf: "end" }}
                >
                  <img
                    src={"/assets/arrow.svg"}
                    alt=""
                    className="ArrowAnimate"
                    onClick={goToNextTarget}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ bgcolor: "#263657", p: "11px" }} component="footer">
                {/* <LwsSchools /> */}
                {isIndividual ? (
                  <Typography
                    variant="subtitle2"
                    color="#fff"
                    align="center"
                    sx={{ letterSpacing: "2px" }}
                    style={{ fontStyle: "initial" }}
                  >
                    <Link color="inherit" href={"/"} underline="hover">
                      MAIN WEBSITE
                    </Link>
                  </Typography>
                ) : (
                  <Typography
                    variant="subtitle2"
                    color="#fff"
                    align="center"
                    sx={{ letterSpacing: "2px" }}
                    style={{ fontStyle: "initial" }}
                  >
                    <Link color="inherit" href={"/thane"} underline="hover">
                      THANE
                    </Link>{" "}
                    |{" "}
                    <Link color="inherit" href={"/cbgpalava"} underline="hover">
                      CBG PALAVA
                    </Link>{" "}
                    |{" "}
                    <Link color="inherit" href={"/lsgpalava"} underline="hover">
                      LSG PALAVA
                    </Link>{" "}
                    |{" "}
                    <Link color="inherit" href={"/taloja"} underline="hover">
                      TALOJA
                    </Link>
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
